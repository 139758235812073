<script setup lang="ts">
import XoIcon from '~/components/ui/XoIcon.vue'
import { useToggle } from '~/composables/useToggle'
const route = useRoute()

export type Tab = {
  key: string
  label: string
  to: string
  isDropdownToggle?: boolean
  disabled?: boolean
}

defineProps({
  tabs: {
    type: Array as () => Tab[],
    default: () => [],
  },
})

const isActive = (tab: Tab) => {
  return tab.to && route.path.startsWith(tab.to)
}

const el = ref(null)
const { opened, open, close } = useToggle(false)
// Event listeners
const { addCloseEvents, removeCloseEvents } = useCloseEventListener(
  el,
  opened,
  close
)

const openedKey = ref<Tab['key']>('')
const onTabClick = (key: string) => {
  openedKey.value = key
  if (!opened.value) {
    setTimeout(() => {
      open()
      addCloseEvents()
    }, 50)
  } else {
    close()
    removeCloseEvents()
  }
}

watch(() => route.path, close)
</script>

<template>
  <div ref="el">
    <div class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.key"
        class="tab"
        :class="{
          selected: isActive(tab),
          disabled: tab.disabled,
        }"
      >
        <div v-if="tab.disabled" class="tab-label disabled">
          {{ tab.label }}
        </div>
        <button
          v-else-if="tab.isDropdownToggle"
          class="tab-button"
          @click="onTabClick(tab.key)"
        >
          <div class="d-flex align-items-center">
            <XoIcon
              size="sm"
              class="icon"
              name="sort"
              :class="[
                { selected: isActive(tab), '-transform-rotate-90': !opened },
              ]"
            />
            <span
              class="tab-label"
              :class="{
                selected: isActive(tab),
              }"
            >
              {{ tab.label }}
            </span>
          </div>
        </button>
        <nuxt-link
          v-else-if="tab.to"
          :to="tab.to"
          class="tab-label"
          :class="{
            selected: isActive(tab),
          }"
        >
          {{ tab.label }}
        </nuxt-link>
      </div>
    </div>
    <slot name="dropdown" :opened="opened" :opened-key="openedKey" />
  </div>
</template>

<style lang="scss" scoped>
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

.tabs {
  display: flex;
  overflow-x: scroll;
  height: $tabs-height;
  width: 100vw;
  background-color: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior: none;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.025);

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    min-width: 160px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.selected {
      background: rgba($order-color-main, 5%);
      border-bottom: 2px solid $order-color-main;
      font-weight: bold;
    }
    &.disabled {
      color: $order-color-gray100;
      cursor: not-allowed;
    }
    &:hover:not(.disabled) {
      background: rgba($order-color-main, 5%);
    }

    .tab-button {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        margin-right: -16px;
        margin-left: -8px;

        &.selected {
          color: $order-color-main;
        }

        & + .tab-label {
          padding-left: 16px;
        }
      }

      .tab-label {
        text-decoration: none;
        color: $base-color-text;

        &.selected {
          color: $order-color-main;
          font-weight: bold;
        }
        &.disabled {
          color: $order-color-gray100;
        }
      }
    }

    .tab-label {
      text-decoration: none;
      color: $base-color-text;
      height: 100%;
      width: 100%;
      text-align: center;
      align-content: center;

      &.selected {
        color: $order-color-main;
        font-weight: bold;
      }
      &.disabled {
        color: $order-color-gray100;
      }
    }
  }
}

:deep(.-transform-rotate-90) {
  transform: rotate(-90deg);
}
</style>
