<script setup lang="ts">
import { Tab } from '~/components/ui/header/XoTabs.vue'
import XoFooter from '~/components/ui/XoFooter.vue'
import XoHeader from '~/components/ui/header/XoHeader.vue'

const { data } = useAuth()
const supplierName = data.value.supplier.company_name
const supplierId = data.value.supplier.id
const userId = data.value.id
const { hasBases, fetchBasesAllCount } = useBases(supplierId, userId)

const defaultTabs: Tab[] = [
  {
    key: 'summary',
    label: 'サマリー',
    to: '/summary',
  },
  {
    key: 'cumulative-report',
    label: '累計レポート',
    to: '/report/cumulative',
  },
  {
    key: 'period-report',
    label: '期間レポート',
    to: '/report/period',
  },
  {
    key: 'section-report',
    label: '担当区分別',
    to: '/report/section',
  },
  {
    key: 'base-report',
    label: '拠点別',
    to: '/report/base',
  },
  {
    key: 'promotion-report',
    label: '販促レポート',
    to: '/report/promotion/flyer',
    isDropdownToggle: true,
  },
  // {
  //   key: 'ranking',
  //   label: 'ランキング',
  //   disabled: true,
  // },
  // {
  //   key: 'customer-management',
  //   label: '顧客管理・分析',
  //   disabled: true,
  // },
]

/* --- computed --- */
const displayTabs = computed((): Tab[] => {
  return hasBases.value
    ? defaultTabs
    : defaultTabs.filter((tab) => tab.key !== 'base-report')
})

/* --- hooks --- */
onBeforeMount(() => fetchBasesAllCount())
</script>

<template>
  <div>
    <!-- <XoEnvironmentalDisplay /> -->
    <XoHeader :supplier-name="supplierName" :tabs="displayTabs" />
    <div class="layout-default">
      <main class="main-wrapper">
        <slot class="content-body" />
      </main>
    </div>
    <XoFooter class="default-footer" />
  </div>
</template>

<style scoped lang="scss">
.layout-default {
  padding-top: calc($header-height + $tabs-height + 1px);

  .main-wrapper {
    min-height: calc(100vh - $header-height - $tabs-height - $footer-height);
    display: flex;

    .content-body {
      width: 100%;
    }
  }
}
</style>
