<script setup lang="ts">
import ReportSection from './ReportSection.vue'

type Props = {
  opened: boolean
}

defineProps<Props>()

type Link = {
  text: string
  href: string
}

type Menu = {
  title: string
  links: Link[]
  icon: string
  iconSize: string
}

const menus: Menu[] = [
  {
    title: 'チラシレポート',
    links: [
      { text: '基本レポート', href: '/report/promotion/flyer' },
      { text: 'チラシ別レポート', href: '/report/promotion/flyer/selection' },
    ],
    icon: 'flyer',
    iconSize: '67',
  },
  // {
  //   title: 'カタログレポート',
  //   links: [
  //     { text: '基本レポート', href: '' },
  //     { text: 'カタログ別レポート', href: '' },
  //   ],
  //   icon: 'catalog',
  //   iconSize: '53',
  // },
]
</script>

<template>
  <div class="accordion" :class="{ 'accordion--open': opened }">
    <div class="accordion__content">
      <div class="accordion__section accordion__section--banner">
        販促レポート
      </div>
      <ReportSection
        v-for="menu in menus"
        :key="menu.title"
        class="accordion__section--report"
        :icon="menu.icon"
        :icon-size="menu.iconSize"
        :title="menu.title"
        :links="menu.links"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.accordion {
  top: calc($header-height + $tabs-height + 1px);
  width: 100%;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  max-height: 0;
  position: absolute;

  &--open {
    max-height: 268px;
  }
  &__content {
    background-color: white;
    box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 0.025);
    display: flex;
  }

  &__section {
    padding: 24px 32px;
    &--banner {
      background-color: $order-color-main;
      color: white;
      font-weight: bold;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
    }

    &--report {
      &:not(:last-child) {
        border-right: 1px solid $order-color-border-gray;
      }
    }
  }
}
</style>
