const runtimeConfig = useRuntimeConfig()
const orderingApiUrl = runtimeConfig.public.ORDERING_API_URL
const { $get } = useHttpClient(orderingApiUrl as string)

type BasesAllCount = {
  count: number
}

export function useBases(supplierId: number, userId: number) {
  const hasBases = ref<boolean>(false)

  const fetchBasesAllCount = async () => {
    const response = await $get<BasesAllCount>(
      '/supplier_order/me/bases/all_count',
      {
        headers: {
          'Xo-Supplier-Id': supplierId.toString(),
          'Xo-User-Id': userId.toString(),
        },
      }
    )

    hasBases.value = response.count > 0
  }

  return { hasBases, fetchBasesAllCount }
}
