<script setup lang="ts">
const nowYear = new Date().getFullYear()
</script>

<template>
  <footer class="xo-footer">
    <div>
      <a href="https://xmart.co.jp/company/" target="_blank">会社概要</a> |
      <a href="https://xmart.co.jp/privacy/" target="_blank"
        >プライバシーポリシー</a
      >
      | <a href="https://xmart.co.jp/terms/" target="_blank">利用規約</a>
    </div>
    <div>&copy; {{ nowYear }} XMart,Inc.</div>
  </footer>
</template>

<style scoped lang="scss">
.xo-footer {
  height: $footer-height;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
